import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import { MDXProvider } from "@mdx-js/react"
import { MDXRenderer } from "gatsby-plugin-mdx"

import Layout from "../components/layout"
import shortcodes from "../components/shortcodes"

import { pageSlug } from "../lib/page-slug-full"
import { breakpoints } from "../parameters/theme"

const shortcodeComps = {
  ...shortcodes,
  a: shortcodes.MDXLink,
}

const Container = styled.div`
  margin-left: auto;
  margin-right: auto;
  margin-top: 4em;
  max-width: ${breakpoints.tablet}px;
  padding: 1em 1em 0;
  overflow-wrap: break-word;

  &::after {
    clear: both;
    content: ".";
    display: block;
    height: 0;
    visibility: hidden;
  }

  & > *:not(:first-child) {
    margin-top: 1em;
  }

  & > a {
    display: block;
  }

  @media (max-width: ${breakpoints.tablet}px) {
    margin-top: 0;
  }
`

const PageTemplate = ({
  data: { contentfulPage: page },
  location,
  pageContext,
}) => (
  <Layout
    location={location}
    pageSlug={pageSlug(page)}
    pageType="page"
    pageClassName="page"
    title={page.title}
    description={page.excerpt ? page.excerpt.excerpt : ""}
    nodeData={page}
  >
    <MDXProvider components={shortcodeComps}>
      <Container>
        {page.childContentfulMdx && page.childContentfulMdx.childMdx ? (
          <MDXRenderer>{page.childContentfulMdx.childMdx.body}</MDXRenderer>
        ) : (
          <div
            dangerouslySetInnerHTML={{
              __html: page.body ? page.body.body : "",
            }}
          ></div>
        )}
      </Container>
    </MDXProvider>
  </Layout>
)

export const query = graphql`
  query GetPageByID($contentfulId: String) {
    contentfulPage(contentful_id: { eq: $contentfulId }) {
      title
      slug
      excerpt {
        excerpt
      }
      childContentfulMdx {
        childMdx {
          body
        }
      }
      body {
        body
      }
      createdAt(formatString: "DD MMM, Y")
      updatedAt(formatString: "DD MMM, Y")
      internal {
        type
      }
    }
  }
`

export default PageTemplate
